.abc-button-color {
  background-color: rgba(252, 91, 32, 0.836) !important;
  border: 1px rgb(173, 51, 7) !important;
}

.abc-button-color-white {
  background-color: rgba(252, 251, 250, 0.664) !important;
  border: 2px rgb(173, 51, 7) !important;
}

.abc-button-color-gray {
  background-color: rgba(179, 179, 179) !important;
  border: 2px rgb(173, 51, 7) !important;
}

.ng-touched::-webkit-input-placeholder { color: #cccccc; }
.ng-untouched::-webkit-input-placeholder { color: #cccccc; }
/* add additional vendor prefixes or utilize mixin */

.mat-header-cell {
  min-height: 12px;
  font-size: smaller;
  font-weight: bold;
}